import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [0];

export const dictionary = {
		"/[locale=locale]": [6],
		"/[locale=locale]/auswaerts/anfragen/[gameSerial]/[gameDay]": [~8,[2]],
		"/[locale=locale]/auswaerts/konto": [~9,[2]],
		"/[locale=locale]/auswaerts/login": [~10,[2]],
		"/[locale=locale]/auth/error": [11],
		"/[locale=locale]/auth/login": [~12],
		"/[locale=locale]/auth/logout": [~13],
		"/[locale=locale]/auth/post-login": [~14],
		"/[locale=locale]/dev": [15,[3]],
		"/[locale=locale]/dev/auth": [16,[3]],
		"/[locale=locale]/dev/cache-bug": [17,[3]],
		"/[locale=locale]/dev/forms": [~18,[3]],
		"/[locale=locale]/dev/i18n": [19,[3]],
		"/[locale=locale]/dev/placeholders": [~20,[3]],
		"/[locale=locale]/dev/scan": [21,[3]],
		"/[locale=locale]/dev/search": [~22,[3]],
		"/[locale=locale]/dev/sentry": [23,[3]],
		"/[locale=locale]/events/[slug]": [24],
		"/[locale=locale]/pages/[categorySlug]/[pageSlug]": [~25],
		"/[locale=locale]/ticketboerse": [~26,[4]],
		"/[locale=locale]/ticketboerse/einstellen": [~27,[4]],
		"/[locale=locale]/ticketboerse/einstellen/[barcode]/[eventID]": [~28,[4]],
		"/[locale=locale]/ticketboerse/login": [~29,[4]],
		"/[locale=locale]/ticketboerse/tickets": [~30,[4]],
		"/[locale=locale]/ticketboerse/tickets/[ticketMarketId]": [~31,[4]],
		"/[locale=locale]/[navSegment]/[navCategory]": [7],
		"/[...url=catchall]": [~5]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';