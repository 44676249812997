import type { HandleServerError, RequestEvent } from '@sveltejs/kit';
import { ErrorExtra } from '$lib/ErrorExtra';
import { browser, dev } from '$app/environment';
import { getLogger } from '$lib/logging/Log';
import { v4 } from '@lukeed/uuid';

export const sharedHandleError: HandleServerError = async ({ error, event }) => {
	console.error(error);

	if (event.route.id === null)
		return {
			message: error instanceof Error ? error.message : 'Not found'
		};

	const traceId = v4();
	if (!dev) {
		await captureError(traceId, error, event);
	}

	return {
		traceId,
		errorCode: getErrorCode(error),
		message: error instanceof Error ? error.message : 'Internal error'
	};
};

async function captureError(traceId: string, error: unknown, event: RequestEvent) {
	const logger = await getLogger();
	logger.instance.setUser({
		id: event?.locals?.sid
	});
	logger.instance.captureException(error, {
		event_id: traceId,
		contexts: { sveltekit: { event } } as any,
		captureContext: {
			extra: {
				traceId,

				// Explicitly attach the error reason, so it doesn't get stringified
				reason: error instanceof ErrorExtra ? error.reason : undefined,

				routeId: event.route.id,
				routeParams: event.route.id ? event.params : undefined
			}
		},
		tags: browser ? ({ client: true } as any) : ({ server: true } as any)
	});
}

// Recurses the error chain to find the first error code
function getErrorCode(error: unknown): string | undefined {
	if (!(error instanceof ErrorExtra)) return undefined;
	if (error.errorCode) return error.errorCode;
	return getErrorCode(error.reason);
}
